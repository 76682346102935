import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import SEO from '@/components/SEO/Seo'

import Layout from '@/components/Layout'

export const HomePageTemplate = ({ data }) => {
  return (
    <>
      <section className='hero'>
        <div className='hero__background'>
          <Img
            className='hero__background__src'
            loading='eager'
            critical={true}
            fluid={data.hero.background.childImageSharp.fluid}></Img>
        </div>
        <div className='container-lg'>
          <div className='hero__wrapper'>
            <div className='decorator'></div>
            <ReactMarkdown
              children={data.hero.content}
              className='hero__content text-center'
            />
            <div className='flex justify-center flex-wrap'>
              <Link
                to='/leasing-enquiries/'
                target='_blank'
                className='btn btn--primary m-2'>
                Become a Capital Food Market Trader
              </Link>
              <a
                href='7196_CFM_LeasingOpportunity_A4_Digital_130820.pdf'
                rel='noreferrer noopener'
                target='_blank'
                className='btn btn--primary m-2'>
                View our Leasing Brochure
              </a>
              <a
                href='http://www.belconnenmarkets.com.au/'
                target='_blank'
                rel='noreferrer noopener'
                className='btn btn--primary m-2'>
                About Belconnen Fresh Food Markets
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className='container-lg pt-20 pb-32' id='joinus'>
        <h2 className='title-1 text-center mb-8 md:mb-20'>
          {data.about.title}
        </h2>
        <div className='cols'>
          <div className='col col--content mt-12'>
            <ReactMarkdown
              children={data.about.content}
              className='content-block'></ReactMarkdown>
            <Link
              to='/leasing-enquiries/'
              className='btn btn--primary my-2 mr-2'>
              Leasing Enquiries
            </Link>
            <Link to='/contact/' className='btn btn--primary my-2'>
              General Enquiries
            </Link>
          </div>
          <div className='col col--image'>
            <div className='col__image'>
              <Img
                className='col__image__src'
                fluid={data.about.image.childImageSharp.fluid}></Img>
            </div>
          </div>
        </div>
      </section>

      <section className='container-lg pb-10'>
        <div className='bg-yellow-light py-20'>
          <div className='container'>
            <ReactMarkdown
              children={data.banner}
              className='max-w-3xl text-h6 text-center mx-auto'></ReactMarkdown>

            <div className='flex items-center justify-center mt-5 -mb-5'>
              <a
                href='https://www.facebook.com/belconnenfreshfoodmarkets'
                target='_blank'
                rel='noreferrer noopener'
                className='btn btn--blue m-2'>
                Facebook
              </a>
              <a
                href='http://www.belconnenmarkets.com.au/'
                target='_blank'
                rel='noreferrer noopener'
                className='btn btn--primary m-2'>
                Website
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO data={frontmatter.seo} />
      <HomePageTemplate data={frontmatter} />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query HomePageTemplate {
    markdownRemark(frontmatter: { template: { eq: "index" } }) {
      frontmatter {
        title
        hero {
          content
          background {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 72) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        about {
          title
          content
          image {
            childImageSharp {
              fluid(maxWidth: 570, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        banner
        ...SEO
      }
    }
  }
`

export default HomePage
